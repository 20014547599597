/** @jsx jsx */
import { jsx, Text, Button } from "theme-ui";
import { Fragment } from "react";
import { graphql } from "gatsby";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/fp/uniqBy";
import flatten from "lodash/fp/flatten";
import values from "lodash/fp/values";
import compose from "lodash/fp/compose";

import {
  BackLink,
  Card,
  ExhibitionsList,
  Heading,
  PortableText,
  ProtectedImage,
  PublicationsList,
  Layout,
  Link,
  Section,
} from "components";
import { getArtistLifeSpan, getArtistName, getArtworkPath } from "utils";

function ArtistPage({
  data: {
    sanityArtist: {
      biography,
      cv,
      yearOfBirth,
      yearOfDeath,
      first_name,
      image,
      last_name,
      artworks,
      exhibitions,
      publications,
    },
  },
}) {
  const artistName = [first_name, last_name].filter(Boolean).join(" ");
  const groupedArtworks = groupBy(artworks, "medium.medium");

  const artworkMediumOrder = [
    "Painting",
    "Sculpture",
    "Works on Paper",
    "Photography",
    "Prints",
  ];

  const artworksForLayout = compose(
    uniqBy("_id"),
    flatten,
    values
  )(groupedArtworks);

  return (
    <Layout pageTitle={[artistName, "Artists"]} artworks={artworksForLayout}>
      {({ openArtworkModal, openContactForm }) => {
        const handleArtworkClick = (artwork) => (evt) => {
          evt.preventDefault();
          openArtworkModal({ artworkId: artwork._id });
        };

        return (
          <Fragment>
            <BackLink to="/artists" sx={{ mb: "s" }}>
              artists
            </BackLink>

            <div sx={{ mb: "s" }}>
              <Heading>{artistName}</Heading>
              {(yearOfBirth || yearOfDeath) && (
                <Text as="p" sx={{ color: "primary", fontWeight: "bold" }}>
                  ({getArtistLifeSpan(yearOfBirth, yearOfDeath)})
                </Text>
              )}
            </div>

            <div sx={{ overflow: "hidden" }}>
              {image?.asset?.gatsbyImageData && (
                <ProtectedImage
                  image={image.asset.gatsbyImageData}
                  alt={artistName}
                  sx={{
                    maxWidth: [null, "420px", "640px"],
                    float: [null, "right"],
                    ml: [null, "m"],
                    mb: ["s", "m"],
                  }}
                />
              )}

              {biography && <PortableText content={biography} />}

              {cv?.asset?.url && (
                <Text as="p">
                  <Link href={cv.asset.url} target="_blank" rel="noreferrer">
                    Download CV
                  </Link>
                </Text>
              )}
            </div>

            <div sx={{ clear: "both", mt: "l" }}>
              {artworks.length === 0 && (
                <Section heading="Artworks">
                  <Button
                    type="button"
                    onClick={openContactForm}
                    variant="links.primary"
                  >
                    Please contact the gallery for details.
                  </Button>
                </Section>
              )}

              {artworkMediumOrder
                .filter((medium) => Boolean(groupedArtworks[medium]))
                .map((medium) => (
                  <Section key={medium} heading={medium}>
                    <div
                      sx={{
                        mx: "-m",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {groupedArtworks[medium].map((artwork) => (
                        <div
                          key={artwork._id}
                          sx={{ m: "m", mt: "s", maxWidth: "330px" }}
                        >
                          <Card
                            to={getArtworkPath({
                              _id: artwork._id,
                              artist: { first_name, last_name },
                            })}
                            gatsbyImageData={
                              artwork?.work_image?.asset?.gatsbyImageData
                            }
                            imageAlt={artwork.name}
                            onClick={handleArtworkClick(artwork)}
                            subTitle={getArtistName(artwork.artist)}
                            title={[artwork.name, artwork.work_date]
                              .filter(Boolean)
                              .join(", ")}
                          />
                        </div>
                      ))}
                    </div>
                  </Section>
                ))}
            </div>

            {exhibitions.length > 0 && (
              <ExhibitionsList
                heading="Exhibitions"
                exhibitions={exhibitions}
              />
            )}

            {publications.length > 0 && (
              <PublicationsList
                heading="Publications"
                publications={publications}
              />
            )}
          </Fragment>
        );
      }}
    </Layout>
  );
}

export const query = graphql`
  query Artist($id: String) {
    sanityArtist(_id: { eq: $id }) {
      _id
      first_name
      yearOfDeath: date_of_death(formatString: "yyyy")
      yearOfBirth: date_of_birth(formatString: "yyyy")
      cv {
        asset {
          url
        }
      }
      last_name
      image {
        asset {
          gatsbyImageData
        }
      }
      biography: _rawBiography(resolveReferences: { maxDepth: 10 })
      exhibitions {
        _id
        name
        year: start_date(formatString: "yyyy")
        start_date
        start_date_string: start_date(formatString: "Do MMMM YYYY")
        end_date
        end_date_string: end_date(formatString: "Do MMMM YYYY")

        artworks {
          artist {
            create_web_page
            first_name
            last_name
            yearOfDeath: date_of_death(formatString: "yyyy")
            yearOfBirth: date_of_birth(formatString: "yyyy")
          }
          is_archived
          name
          work_title
          work_date
          work_image {
            asset {
              gatsbyImageData(height: 220, width: 290)
            }
          }
        }

        featured_artwork {
          _id
          name
          work_image {
            asset {
              gatsbyImageData(height: 220, width: 290)
            }
          }
          work_image__large: work_image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 600)
            }
          }
          work_title
          artist {
            _id
            first_name
            last_name
            yearOfDeath: date_of_death(formatString: "yyyy")
            yearOfBirth: date_of_birth(formatString: "yyyy")
          }
          work_date
        }
      }
      publications {
        _id
        title
        publication_date_site
        image {
          asset {
            gatsbyImageData(height: 220, width: 290)
          }
        }
      }
      artworks {
        _id
        name
        medium {
          medium
        }
        work_image {
          asset {
            gatsbyImageData(height: 220)
          }
        }
        image: work_image {
          asset {
            gatsbyImageData(height: 800)
          }
        }
        image__short: work_image {
          asset {
            gatsbyImageData(height: 500)
          }
        }
        work_title
        artist {
          first_name
          last_name
          yearOfDeath: date_of_death(formatString: "yyyy")
          yearOfBirth: date_of_birth(formatString: "yyyy")
        }
        work_date
        content: _rawDetails(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;

export default ArtistPage;
